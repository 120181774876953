import api from "data/APIs";
import dataProvider from "data/dataProvider";
import { put, takeEvery, call, select, takeLatest } from "redux-saga/effects";
import {
  convertCreativeSessionDataToPayload,
  convertPayloadToCreativeSessionData,
} from "ui/pages/marketing/MarketingCreatives/data";
import { is_empty } from "utils/validations";
import { CREATIVES_TABS, PERSONA_TABS } from "../Ads/Utils";
import {
  EXLY_MARKETING__CREATE_MARKETING_CREATIVE,
  EXLY_MARKETING__DELETE_MARKETING_CREATIVE,
  EXLY_MARKETING__GET_MARKETING_CREATIVES,
  EXLY_MARKETING__GET_MARKETING_CREATIVE_DETAILS,
  EXLY_MARKETING__REQUEST_CREATOR_DOMAIN,
  EXLY_MARKETING__SET_CREATOR_DOMAIN,
  EXLY_MARKETING__SET_CREATOR_NAMESERVERS,
  EXLY_MARKETING__SET_MARKETING_CREATIVES,
  EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS,
  EXLY_MARKETING__UPDATE_MARKETING_CREATIVE,
  EXLY_MARKETING__ADS__GET_CREATIVES,
  EXLY_MARKETING__ADS__SET_CREATIVES,
  EXLY_MARKETING__ADS__SET_CAMPAIGNS,
  EXLY_MARKETING__ADS__GET_CAMPAIGNS,
  EXLY_MARKETING__ADS__GET_CAMPAIGN_EXISTS,
  EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
  EXLY_MARKETING__ADS__GET_CONSUMED_CREATIVES,
  EXLY_MARKETING__ADS__SET_PERSONAS,
  EXLY_MARKETING__ADS__GET_PERSONAS,
  EXLY_MARKETING__ADS__GET_FB_LOCATIONS,
  EXLY_MARKETING__ADS__GET_FB_INTERESTS,
  EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL,
  EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
  EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
  EXLY_MARKETING__ADS__REFRESH_CAMPAIGN_STATS,
} from "./actions";
import { formatNumber } from "features/Common/modules/DataTypes/utils/strings";
import { creative_statuses } from "../../../../features/MarketingCreatives/Constants/MarketingCreatives.constants";

function* getCreatorDomainDetails() {
  try {
    const response = yield call(
      dataProvider.custom_request,
      api.get_custom_domain
    );
    if (response?.status === 200) {
      yield put({
        type: EXLY_MARKETING__SET_CREATOR_DOMAIN,
        payload: response?.data,
      });
    } else {
      throw `API: ${api.get_custom_domain} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_MARKETING__SET_CREATOR_NAMESERVERS,
      payload: null,
    });
  }
}

function* getMarketingCreatives({ params, errorCallback, finallyCallback }) {
  try {
    const reducerData = yield select(
      (state) => state.marketing.marketing_creatives
    );

    const creativeStatus = params?.creativeStatus;
    const newPage = params?.newPage;

    const draftUrl = `${api.marketing_creatives_library.get_marketing_creatives}/?creative_status=${creative_statuses.draft}`;
    const downloadsUrl = `${api.marketing_creatives_library.get_marketing_creatives}/?creative_status=${creative_statuses.download}`;
    const handcraftedUrl = `${api.marketing_creatives_library.get_marketing_creatives}/?creative_status=${creative_statuses.draft},${creative_statuses.download}`;

    // const drafts = yield call(dataProvider.custom_request, draftUrl);
    // const downloads = yield call(dataProvider.custom_request, downloadsUrl);
    let drafts;
    let draftsStatus;
    let downloads;
    let downloadsStatus;
    let handcrafted;
    let handcraftedStatus;
    if (creativeStatus && newPage) {
      if (creativeStatus === creative_statuses.draft) {
        drafts = yield call(
          dataProvider.custom_request,
          `${draftUrl}&page=${newPage}`
        );
        draftsStatus = drafts?.status;
        downloads = {
          data: reducerData?.data?.[creative_statuses.download],
        };
        downloadsStatus = 200;
      } else if (creativeStatus === creative_statuses.handcrafted) {
        const response = yield call(
          dataProvider.custom_request,
          `${handcraftedUrl}&page=${newPage}`
        );
        handcrafted = response;
        handcraftedStatus = response?.status;
      } else {
        drafts = { data: reducerData?.data?.[creative_statuses.draft] };
        draftsStatus = 200;
        downloads = yield call(
          dataProvider.custom_request,
          `${downloadsUrl}&page=${newPage}`
        );
        downloadsStatus = downloads?.status;
      }
    } else {
      handcrafted = yield call(dataProvider.custom_request, handcraftedUrl);
      handcraftedStatus = handcrafted?.status;
    }

    if (draftsStatus === 200 && downloadsStatus === 200) {
      yield put({
        type: EXLY_MARKETING__SET_MARKETING_CREATIVES,
        payload: {
          data: {
            [creative_statuses.download]: downloads?.data,
            [creative_statuses.draft]: drafts?.data,
          },
          loaded: true,
        },
      });
    } else if (handcraftedStatus === 200) {
      yield put({
        type: EXLY_MARKETING__SET_MARKETING_CREATIVES,
        payload: {
          data: {
            [creative_statuses.handcrafted]: handcrafted?.data,
          },
          loaded: true,
        },
      });
    } else {
      throw `API: ${api.marketing_creatives_library.get_marketing_creatives} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_MARKETING__SET_MARKETING_CREATIVES,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
  if (finallyCallback) yield call(finallyCallback);
}

function* getMarketingCreativeDetails({
  creative_uuid,
  successCallback,
  errorCallback,
}) {
  try {
    const creativeDetials = yield call(
      dataProvider.custom_request,
      api.marketing_creatives_library.get_marketing_creative_details +
        "/" +
        creative_uuid
    );
    if (creativeDetials?.status === 200) {
      yield put({
        type: EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS,
        payload: {
          creative_uuid,
          data: convertPayloadToCreativeSessionData(
            creativeDetials?.data?.creatives
          ),
        },
      });
      if (successCallback) yield call(successCallback);
    } else {
      throw `API: ${api.marketing_creatives_library.get_marketing_creative_details} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_MARKETING__SET_MARKETING_CREATIVES,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* updateMarketingCreative({
  payload,
  successCallback,
  finallyCallback,
  errorCallback,
}) {
  try {
    const response = yield call(
      dataProvider.custom_request,
      api.marketing_creatives_library.update_marketing_creative,
      "POST",
      convertCreativeSessionDataToPayload(payload)
    );
    if (response?.status === 200) {
      yield put({
        type: EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS,
        payload: {
          creative_uuid: payload?.creative_uuid,
          data: payload,
        },
      });
      if (successCallback) yield call(successCallback);
    } else {
      throw `API: ${api.marketing_creatives_library.update_marketing_creative} FAIL`;
    }
    if (finallyCallback) yield call(finallyCallback);
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* deleteMarketingCreative({ payload, successCallback, errorCallback }) {
  try {
    const response = yield call(
      dataProvider.custom_request,
      api.marketing_creatives_library.update_marketing_creative,
      "POST",
      payload
    );
    if (response?.status === 200) {
      if (successCallback) yield call(successCallback);
    } else {
      throw `API: ${api.marketing_creatives_library.update_marketing_creative} FAIL`;
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* createMarketingCreative({ payload, successCallback, errorCallback }) {
  try {
    const response = yield call(
      dataProvider.custom_request,
      api.marketing_creatives_library.create_marketing_creative,
      "POST",
      payload
    );
    if (response?.status === 200) {
      if (successCallback)
        successCallback({
          creative_uuid: response?.data?.creative?.creative_uuid,
          tracking_link: response?.data?.creative?.affiliate_url,
        });
    } else {
      throw `API: ${api.marketing_creatives_library.create_marketing_creative} FAIL`;
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* getCreatives({
  successCallback,
  errorCallback,
  force_update,
  params,
}) {
  try {
    if (is_empty(params)) {
      return errorCallback
        ? yield call(errorCallback)
        : console.error("Params missing");
    }
    let creative_type = params.creative_type;
    delete params.creative_type;
    const creatives = yield select((state) => state.marketing.ads.creatives);
    if (is_empty(creatives[creative_type]) || force_update) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.creatives.list_default_creatives,
        "GET",
        params
      );
      if (response?.status === 200) {
        let status_creatives = {};
        status_creatives[creative_type] = response?.data?.default_creatives;
        yield put({
          type: EXLY_MARKETING__ADS__SET_CREATIVES,
          payload: status_creatives,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${api.ad_creation.creatives.list_default_creatives} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_MARKETING__ADS__SET_CREATIVES,
        payload: creatives,
      });
    }
  } catch (error) {
    console.error("error", error);
    yield put({
      type: EXLY_MARKETING__ADS__SET_CREATIVES,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* getPersonas({ successCallback, errorCallback, force_update }) {
  try {
    const ads_data = yield select((state) => state.marketing.ads);
    const personas = ads_data.personas;
    const persona_type = force_update
      ? PERSONA_TABS.creator.slug
      : ads_data.active_persona_tab;
    if (is_empty(personas[persona_type]) || force_update) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.personas[`list_${persona_type}_personas`]
      );
      if (response?.status === 200) {
        let status_personas = {};
        status_personas[persona_type] =
          persona_type === PERSONA_TABS.default.slug
            ? response?.data?.default_persona
            : response?.data?.consumed_persona;
        yield put({
          type: EXLY_MARKETING__ADS__SET_PERSONAS,
          payload: status_personas,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${
          api.ad_creation.personas[`list_${persona_type}_personas`]
        } FAIL`;
      }
    } else {
      yield put({ type: EXLY_MARKETING__ADS__SET_PERSONAS, payload: personas });
    }
  } catch (error) {
    console.error("error", error);
    yield put({
      type: EXLY_MARKETING__ADS__SET_PERSONAS,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* getConsumedCreatives({
  successCallback,
  errorCallback,
  force_update,
}) {
  try {
    let creative_type = CREATIVES_TABS.consumed.slug;
    const creatives = yield select((state) => state.marketing.ads.creatives);
    if (is_empty(creatives[creative_type]) || force_update) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.creatives.list_consumed_creatives
      );
      if (response?.status === 200) {
        let status_creatives = {};
        status_creatives[creative_type] = response?.data?.consumed_creatives;
        yield put({
          type: EXLY_MARKETING__ADS__SET_CREATIVES,
          payload: status_creatives,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${api.ad_creation.creatives.list_consumed_creatives} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_MARKETING__ADS__SET_CREATIVES,
        payload: creatives,
      });
    }
  } catch (error) {
    console.error("error", error);
    yield put({
      type: EXLY_MARKETING__ADS__SET_CREATIVES,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* getFbLocations({ successCallback, errorCallback, query }) {
  try {
    if (!is_empty(query && successCallback)) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.personas.location_list,
        "GET",
        { query: query }
      );
      if (response?.status === 200) {
        let data = response.data.data.map((item) => {
          return {
            type: item.type,
            name: item.name,
            label: `${item.name}${
              item.region ? `,${"\u00A0"}${item.region}` : ""
            },${"\u000a"}${item.country_name}`,
            value: item.key,
          };
        });
        if (successCallback) yield call(successCallback(data));
      } else {
        if (errorCallback) yield call(errorCallback);
        throw `API: ${api.ad_creation.personas.location_list} FAIL`;
      }
    }
  } catch (error) {
    console.error("error", error);
  }
}

function* getFbInterests({ successCallback, errorCallback, query }) {
  try {
    if (!is_empty(query && successCallback)) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.personas.interest_list,
        "GET",
        { query: query }
      );
      if (response?.status === 200) {
        let data = response.data.data.map((item) => {
          const formattedLowerBound = item.audience_size_lower_bound;
          const formattedUpperBound = item.audience_size_upper_bound;
          return {
            // @dev
            //Earlier topic is mapped in place of path[0],
            // but it is not giving us interests, demographic etc category for Targeting audience.
            // So, as per discussed with current scope have to map path[0].
            label: item.name,
            name: item.name,
            value: item.id,
            topic: item?.path[0],
            size:
              formatNumber(formattedLowerBound) +
              " - " +
              formatNumber(formattedUpperBound),
          };
        });
        if (successCallback) yield call(successCallback(data));
      } else {
        if (errorCallback) yield call(errorCallback);
        throw `API: ${api.ad_creation.personas.interest_list} FAIL`;
      }
    }
  } catch (error) {
    console.error("error", error);
  }
}

function* getCampaigns({
  successCallback,
  switchToDraft = undefined,
  errorCallback,
  force_update,
  params,
}) {
  try {
    if (is_empty(params?.status)) {
      if (errorCallback) yield call(errorCallback);
    }
    const campaigns = yield select((state) => state.marketing.ads.campaigns);
    if (is_empty(campaigns) || force_update) {
      const response = yield call(
        dataProvider.custom_request,
        api.ad_creation.campaigns.list_campaigns,
        "GET",
        params
      );
      if (response?.status === 200) {
        // if empty live campaigns on first load switch to drafts tab
        if (
          switchToDraft !== undefined &&
          is_empty(response?.data?.campaigns)
        ) {
          return yield call(switchToDraft);
        }

        let status_campaign = {};
        status_campaign[`${params?.status}`] = response?.data?.campaigns;
        yield put({
          type: EXLY_MARKETING__ADS__SET_CAMPAIGNS,
          payload: status_campaign,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${api.ad_creation.campaigns.list_campaigns} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_MARKETING__ADS__SET_CAMPAIGNS,
        payload: campaigns,
      });
    }
  } catch (error) {
    yield put({
      type: EXLY_MARKETING__ADS__SET_CAMPAIGNS,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* getCampaignExists({ successCallback, errorCallback }) {
  try {
    const response = yield call(
      dataProvider.custom_request,
      api.ad_creation.campaigns.check_campaign_exists,
      "GET"
    );
    if (response?.status === 200) {
      yield put({
        type: EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
        payload: response?.data?.campaign_exists,
      });
      if (successCallback) yield call(successCallback);
    } else {
      throw `API: ${api.ad_creation.campaigns.check_campaign_exists} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
      payload: false,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* getCampaignDetail({ successCallback, errorCallback, payload }) {
  try {
    const ads_data = yield select((state) => state.marketing.ads);
    if (!is_empty(ads_data.campaign_uuid)) {
      const response = yield call(
        dataProvider.custom_request,
        `${api.ad_creation.campaigns.get_campaign_detail}/${ads_data.campaign_uuid}`
      );
      if (
        response?.status === 200 &&
        !is_empty(response.data.campaign_details)
      ) {
        const campaign_details = response.data.campaign_details;
        let temp = ads_data.campaigns;

        for (let key in temp) {
          for (let item of temp[key]) {
            if (item.uuid === ads_data.campaign_uuid) {
              item = Object.assign(item, campaign_details);
              yield put({
                type: EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
                payload: campaign_details.ad_content,
              });
              if (!is_empty(payload)) {
                item = Object.assign(item, payload);
              }
            }
          }
        }

        if (!is_empty(campaign_details?.creatives)) {
          for (let i = 0; i < campaign_details?.creatives.length; i++) {
            let item = campaign_details?.creatives[i];
            item.active = i === 0;
            item.index = i;
          }
        }

        yield put({
          type: EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
          payload: campaign_details?.creatives,
        });

        yield put({
          type: EXLY_MARKETING__ADS__SET_CAMPAIGNS,
          payload: temp,
        });
        if (successCallback) yield call(successCallback(campaign_details));
      } else {
        throw `API: ${api.ad_creation.campaigns.campaign_details} FAIL`;
      }
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* refreshCampaignStats({ successCallback, errorCallback, payload }) {
  try {
    const ads_data = yield select((state) => state.marketing.ads);
    let campaign_uuid = payload;
    if (!is_empty(campaign_uuid)) {
      const response = yield call(
        dataProvider.custom_request,
        `${api.ad_creation.campaigns.refresh_stats}/${campaign_uuid}`,
        "POST"
      );
      if (response?.status === 200) {
        const stats = response.data;
        let temp = ads_data.campaigns;

        for (let key in temp) {
          for (let item of temp[key]) {
            if (item.uuid === campaign_uuid) {
              item = Object.assign(item, stats);
            }
          }
        }

        yield put({
          type: EXLY_MARKETING__ADS__SET_CAMPAIGNS,
          payload: temp,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${api.ad_creation.campaigns.refresh_stats} FAIL`;
      }
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* marketingSaga() {
  yield takeEvery(
    EXLY_MARKETING__REQUEST_CREATOR_DOMAIN,
    getCreatorDomainDetails
  );
  yield takeEvery(
    EXLY_MARKETING__GET_MARKETING_CREATIVES,
    getMarketingCreatives
  );
  yield takeEvery(
    EXLY_MARKETING__GET_MARKETING_CREATIVE_DETAILS,
    getMarketingCreativeDetails
  );
  yield takeEvery(
    EXLY_MARKETING__UPDATE_MARKETING_CREATIVE,
    updateMarketingCreative
  );
  yield takeEvery(
    EXLY_MARKETING__DELETE_MARKETING_CREATIVE,
    deleteMarketingCreative
  );
  yield takeEvery(
    EXLY_MARKETING__CREATE_MARKETING_CREATIVE,
    createMarketingCreative
  );
  yield takeEvery(EXLY_MARKETING__ADS__GET_CREATIVES, getCreatives);
  yield takeEvery(EXLY_MARKETING__ADS__GET_CAMPAIGNS, getCampaigns);
  yield takeEvery(
    EXLY_MARKETING__ADS__GET_CONSUMED_CREATIVES,
    getConsumedCreatives
  );
  yield takeEvery(EXLY_MARKETING__ADS__GET_PERSONAS, getPersonas);
  yield takeLatest(EXLY_MARKETING__ADS__GET_FB_LOCATIONS, getFbLocations);
  yield takeLatest(EXLY_MARKETING__ADS__GET_FB_INTERESTS, getFbInterests);
  yield takeEvery(EXLY_MARKETING__ADS__GET_CAMPAIGN_EXISTS, getCampaignExists);
  yield takeEvery(EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL, getCampaignDetail);
  yield takeEvery(
    EXLY_MARKETING__ADS__REFRESH_CAMPAIGN_STATS,
    refreshCampaignStats
  );
}

export default marketingSaga;
